import React from "react";

const ContactUs = () => {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center mb-8">Contact Us</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column: Contact Details and Form */}
          <div className="space-y-6 flex flex-col justify-center">
            {/* Contact Details */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div className="text-center">
                <h3 className="text-xl font-semibold">Our Office</h3>
                <p className="text-gray-600">123 Main Street</p>
                <p className="text-gray-600">City, State, ZIP</p>
              </div>
              <div className="text-center">
                <h3 className="text-xl font-semibold">Phone</h3>
                <p className="text-gray-600">+1 (123) 456-7890</p>
              </div>
              <div className="text-center">
                <h3 className="text-xl font-semibold">Email</h3>
                <p className="text-gray-600">info@cuive.ng</p>
              </div>
              <div className="text-center">
                <h3 className="text-xl font-semibold">Business Hours</h3>
                <p className="text-gray-600">
                  Monday - Friday: 9:00 AM - 5:00 PM
                </p>
                <p className="text-gray-600">Saturday: 10:00 AM - 2:00 PM</p>
                <p className="text-gray-600">Sunday: Closed</p>
              </div>
            </div>

            {/* Contact Form */}
            <form className="space-y-4">
              <div>
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Your Name"
                />
              </div>
              <div>
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  placeholder="Your Email"
                />
              </div>
              <div>
                <label className="block text-gray-700">Message</label>
                <textarea
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  rows="4"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300"
              >
                Send Message
              </button>
            </form>
          </div>

          {/* Right Column: Map */}
          <div>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.3003160714866!2d5.234930114763517!3d7.612400994508477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1047a7585e3e0a59%3A0x6f6c4d2f2b8c82d7!2s7.6124%C2%B0%20N%2C%205.2371%C2%B0%20E!5e0!3m2!1sen!2sng!4v1594843375498!5m2!1sen!2sng"
              width="100%"
              height="590"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
