import React from "react";
import HeroSection from "./components/HeroSection";
import AboutCuive from "./components/AboutSection";
import WhyCuive from "./components/WhyUsSection";
import JoinUsNow from "./components/JoinUs";
import BlogUpdates from "./components/Blog";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer.js";
function App() {
  return (
    <div className="App">
      <HeroSection />
      <AboutCuive />
      <WhyCuive />
      <JoinUsNow />
      <BlogUpdates />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
