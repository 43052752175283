import React, { useState, useEffect } from "react";
import logo from "../assets/images/lg.png";
import heroImage from "../assets/images/hero.jpg";

const HeroSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="relative min-h-screen flex flex-col">
      <nav
        className={`fixed w-full top-0 z-10 transition duration-300 ${
          isScrolled ? "bg-black text-white" : "bg-transparent text-white"
        }`}
      >
        <div className="flex items-center justify-between px-8 py-4">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="h-12 w-12" />
            <span className="ml-3 text-xl font-semibold">Cuive</span>
          </div>
          <ul className="flex space-x-8 text-lg">
            <li>
              <a href="#about" className="hover:underline">
                About
              </a>
            </li>
            <li>
              <a href="#technologies" className="hover:underline">
                Technologies
              </a>
            </li>
            <li>
              <a href="#ride" className="hover:underline">
                Ride
              </a>
            </li>
            <li>
              <a href="#investment" className="hover:underline">
                Investment
              </a>
            </li>
            <li>
              <a href="#contact" className="hover:underline">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div className="flex-1 flex flex-col justify-center items-center text-center mt-20 px-4">
        <h1 className="text-5xl font-bold text-white">
          Move Faster, Easier and Safer
        </h1>
        <p className="mt-4 text-xl text-white max-w-2xl">
          Cuive is your all-in-one urban transportation solution. Experience
          cost-effective, safe, and eco-friendly rides with top-rated drivers.
          Whether you’re commuting to work or exploring the city, Cuive has you
          covered.
        </p>
        <button className="mt-6 px-8 py-2 bg-[#7e18ac] text-white rounded-full text-lg">
          Get the app
        </button>
      </div>
      <div className="absolute inset-0 z-[-1]">
        <img
          src={heroImage}
          alt="Hero"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
    </section>
  );
};

export default HeroSection;

// import React, { useState, useEffect } from "react";
// import logo from "../assets/images/logo1.PNG";

// const HeroSection = () => {
//   const [isScrolled, setIsScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 50);
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <section className="bg-white text-gray-800 min-h-screen flex flex-col">
//       <nav
//         className={`fixed w-full top-0 z-10 transition duration-300 ${
//           isScrolled ? "bg-black text-white" : "bg-transparent text-[#7e18ac]"
//         }`}
//       >
//         <div className="flex items-center justify-between px-8 py-4">
//           <div className="flex items-center">
//             <img src={logo} alt="Cuive Logo" className="h-12 w-12" />
//             <span className="ml-3 text-xl font-semibold">Cuive</span>
//           </div>
//           <ul className="flex space-x-8 text-lg">
//             <li>
//               <a href="#about" className="hover:underline">
//                 About
//               </a>
//             </li>
//             <li>
//               <a href="#about" className="hover:underline">
//                 Technologies
//               </a>
//             </li>
//             <li>
//               <a href="#about" className="hover:underline">
//                 Ride
//               </a>
//             </li>
//             <li>
//               <a href="#about" className="hover:underline">
//                 Investment
//               </a>
//             </li>

//             <li>
//               <a href="#contact" className="hover:underline">
//                 Contact Us
//               </a>
//             </li>
//             {/* <li>
//               <a href="#faq" className="hover:underline">
//                 FAQ
//               </a>
//             </li> */}
//           </ul>
//         </div>
//       </nav>
//       <div className="container mx-auto text-center flex-1 flex flex-col justify-center mt-20">
//         <h1 className="text-4xl font-bold">Blast past fast.</h1>
//         <p className="mt-4 text-lg text-gray-600">
//           A superpowerful chip. 5G speed. An advanced dual-camera system.
//         </p>
//         <p className="text-lg text-gray-600">
//           A Ceramic Shield front that's tougher than any smartphone glass.
//         </p>
//         <p className="text-lg text-gray-600">
//           And a bright, beautiful OLED display.
//         </p>
//         <button className="mt-6 px-8 w-30 mx-auto py-2 bg-blue-600 text-white rounded-full text-lg">
//           Learn More
//         </button>
//       </div>
//       <div className="mt-8">
//         <img
//           src="https://cdn.globalso.com/huaihaiglobal/WechatIMG93.jpg"
//           alt="Cuive Transportation"
//           className="mx-auto w-1/2"
//         />
//       </div>
//     </section>
//   );
// };

// export default HeroSection;
