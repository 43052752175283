import React from "react";
import { FaCar, FaDollarSign } from "react-icons/fa";

const JoinUsNow = () => {
  return (
    <div
      className="relative bg-cover bg-center"
      style={{
        backgroundImage: `url('https://path-to-your-background-image.jpg')`,
      }}
    >
      <div className="absolute inset-0 bg-purple-800 opacity-75"></div>
      <div className="relative z-10 flex flex-col items-center justify-center h-full py-20">
        <h1 className="text-4xl font-bold text-white mb-8">Join Us Now!</h1>
        <div className="flex space-x-8">
          <button className="flex items-center space-x-4 bg-white text-purple-800 font-semibold py-3 px-6 rounded shadow-lg hover:bg-purple-100">
            <FaCar className="text-2xl" />
            <span>Ride With Us</span>
          </button>
          <button className="flex items-center space-x-4 bg-white text-purple-800 font-semibold py-3 px-6 rounded shadow-lg hover:bg-purple-100">
            <FaDollarSign className="text-2xl" />
            <span>Invest With Us</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinUsNow;
