import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import second from "../assets/images/winner-phone-credit-card-black-600nw-2326402573.webp";
const AboutCuive = () => {
  const [activeTab, setActiveTab] = useState("Earn Monthly");

  const tabs = [
    {
      name: "Earn Monthly",
      title: "Generate Passive Income",
      points: [
        "Invest in vehicles like tricycles, cabs, or electric buses.",
        "Cuive manages driver sourcing, route planning, and maintenance.",
        "Earn monthly returns while Cuive handles daily operations.",
      ],
      button: "Start Earning",
      img: "https://thumbs.dreamstime.com/b/young-african-man-driving-rickshaw-taxi-counting-his-money-smiling-giving-thumbs-up-165833449.jpg",
    },
    {
      name: "Save In Wallets",
      title: "Secure and Manage Your Earnings",
      points: [
        "Load money via bank transfers, cards, or mobile money services.",
        "Track transactions and manage funds effortlessly.",
        "Enjoy secure savings with encryption and two-factor authentication.",
      ],
      button: "Learn more",
      img: "https://verveacu.com/wp-content/uploads/2021/04/0421-BuildYourSavings_Header.jpg",
    },
    {
      name: "Share Wallet With Friends",
      title: "Share Wallet with Ease",
      points: [
        "Send money or split ride costs instantly.",
        "Perfect for families, friends, or colleagues.",
        "Manage shared expenses simply and efficiently.",
      ],
      button: "Learn more",
      img: "https://media.istockphoto.com/id/1367430634/photo/two-young-african-women-having-fun-looking-phone.jpg?s=612x612&w=0&k=20&c=DNapgefRy7Lw1CFR5WpQAvXzlWU2be3KfAAulS_4I3Q=",
    },
    {
      name: "Pay With Cards",
      title: "Seamless Cashless Transactions",
      points: [
        "Link your Cuive card to your digital wallet.",
        "Works across all Cuive-supported transport modes.",
        "Enjoy wallet top-up, transaction history, and spending limits.",
      ],
      button: "Learn more",
      img: "https://img.freepik.com/premium-photo/online-shopping-credit-card-black-woman-with-phone-studio-ecommerce-blue-background-debit-fintech-app-girl-with-subscription-membership-budget-payment-banking_590464-169013.jpg",
    },
    {
      name: "EcoFriendly Rides",
      title: "Choose Eco-Friendly Transportation",
      points: [
        "Electric vehicles reduce carbon emissions.",
        "Support a greener environment with Cuive.",
        "Enjoy comfort and efficiency with eco-friendly rides.",
      ],
      button: "Learn more",
      img: "https://wrench.com/blog/content/images/2019/11/ecofriendly.jpg",
    },
  ];

  const activeTabContent = tabs.find((tab) => tab.name === activeTab);

  return (
    <div className="container mx-auto p-6" id="about">
      {/* Title and Subtitle */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold">Earn and Ride With Cuive</h1>
        {/* <hr /> */}
      </div>

      {/* Tab Buttons */}
      <div className="flex justify-center mb-8">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            className={`px-4 py-2 mx-2 font-semibold border rounded-[0.9em] ${
              activeTab === tab.name
                ? "bg-[#7e18ac] text-white"
                : "bg-white text-[#7e18ac] border-[#7e18ac]"
            }`}
          >
            {tab.name}
          </button>
        ))}
      </div>

      {/* Image and Description with Bullet Points */}
      <TransitionGroup>
        <CSSTransition
          key={activeTabContent.name}
          timeout={5}
          classNames="fade"
        >
          <div className="flex flex-col md:flex-row items-center">
            {/* Left Column: Image */}
            <div className="md:w-1/2 mb-8 md:mb-0">
              <img
                src={activeTabContent.img}
                alt={activeTab}
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>

            {/* Right Column: Description */}
            <div className="md:w-1/2 md:pl-8">
              <h2 className="text-2xl font-semibold mb-4">
                {activeTabContent.title}
              </h2>
              <ul className="list-disc list-inside mb-4 text ">
                {activeTabContent.points.map((point, index) => (
                  <li key={index} className="mb-2">
                    {point}
                  </li>
                ))}
              </ul>
              <button className="bg-[#7e18ac] text-white px-4 py-2 rounded-lg">
                {activeTabContent.button}
              </button>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default AboutCuive;
