import React from "react";

const BlogUpdates = () => {
  const blogPosts = [
    {
      title:
        "Celebrating Earth Month: Driving Toward a Greener, Cleaner Future",
      date: "July 10, 2024",
      description:
        "As we celebrate Earth Month, it's crucial to recognize the importance of environmental sustainability and the role that each of us plays in creating a greener future. ...",
      imageUrl: "https://via.placeholder.com/400x200", // Replace with actual image URL
    },
    {
      title: "ST LOUIS, MO. Now hiring in St Louis, and other cities in MO",
      date: "March 6, 2024",
      description:
        "Driver Bonus, Incentive, and Reward Program. New drivers are getting $500 after completing 100 rides within first 30 days. Hiring for full-time and part-time positions.",
      imageUrl: "https://via.placeholder.com/400x200", // Replace with actual image URL
    },
    {
      title: "ATLANTA, GA. Now hiring in Atlanta, and other cities in GA",
      date: "August 1, 2023",
      description:
        "Driver Bonus, Incentive, and Reward Program. New drivers are getting $500 after completing 100 rides within first 30 days. Hiring for full-time and part-time positions.",
      imageUrl: "https://via.placeholder.com/400x200", // Replace with actual image URL
    },
  ];

  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-[#7e18ac]">
            Cuive News Updates
          </h2>
          <a href="#see-all" className="text-[#7e18ac] font-semibold">
            See All
          </a>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden"
            >
              <img
                className="w-full h-48 object-cover"
                src={post.imageUrl}
                alt={post.title}
              />
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-2">{post.title}</h3>
                <p className="text-sm text-gray-500 mb-4">{post.date}</p>
                <p className="text-gray-700">{post.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogUpdates;
