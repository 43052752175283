import React from "react";
import {
  FaLeaf,
  FaCarSide,
  FaMobileAlt,
  FaShieldAlt,
  FaDollarSign,
  FaClock,
  FaThumbsUp,
  FaRoute,
  FaPeopleCarry,
} from "react-icons/fa";

const WhyCuive = () => {
  return (
    <div className="flex flex-col items-center bg-gray-100 p-8">
      <h1 className="text-4xl font-bold mb-4">Why Cuive?</h1>
      <h2 className="text-xl mb-8 text-center">
        Your Safety First: Ride with Confidence, Arrive with Peace of Mind!
      </h2>
      <div className="flex w-full max-w-7xl">
        <div className="w-3/5 grid grid-cols-1 md:grid-cols-3 gap-8 pr-8">
          <div className="flex items-start space-x-4">
            <FaLeaf className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Eco-Friendly Vehicles</h3>
              <p>
                Our fleet consists of eco-friendly vehicles to reduce carbon
                footprint and ensure a sustainable future.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaCarSide className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">
                Vehicle Investment Safety
              </h3>
              <p>
                We ensure all our vehicles are regularly maintained and meet the
                highest safety standards.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaMobileAlt className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Ease of Use</h3>
              <p>
                Book your ride with just a few taps on our user-friendly app.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaShieldAlt className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">High Security</h3>
              <p>
                We prioritize your safety with top-notch security measures for
                every ride.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaDollarSign className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Affordable Prices</h3>
              <p>
                Our rides are priced competitively to offer you the best value.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaClock className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Timely Arrivals</h3>
              <p>
                Our efficient system ensures that your rides are always on time.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaThumbsUp className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Customer Satisfaction</h3>
              <p>
                We strive for excellence and ensure every ride is a pleasant
                experience.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaRoute className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Optimal Routes</h3>
              <p>
                Our advanced navigation system chooses the best routes to get
                you to your destination quickly.
              </p>
            </div>
          </div>
          <div className="flex items-start space-x-4">
            <FaPeopleCarry className="text-black-600 text-3xl" />
            <div>
              <h3 className="text-2xl font-semibold">Community Support</h3>
              <p>
                We believe in giving back to the community and supporting local
                initiatives.
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/5 flex items-center">
          <img
            src="https://img.freepik.com/premium-photo/check-this-out-happy-charismatic-african-american-woman-with-afro-haircut-holding-smartphone-showing-mobile-screen-pointing-display-as-promote-application-shopping-app-game_1258-7362.jpg?w=740"
            alt="Cuive App"
            className="rounded shadow-lg w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default WhyCuive;
